
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
.EventSponsorStrip {
  background-color: var(--color-theme--event);
  color: $color-white-primary;
  padding-block: 24px;
  position: relative;
  z-index: 10;

  &,
  * {
    box-sizing: border-box;
  }
}

.EventSponsorStripDl {
  --ddMaxHeight: 28px;

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 0;
  overflow: hidden;

  dt {
    margin-right: 20px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    line-height: 1;
  }

  dd {
    height: 100%;
    max-height: var(--ddMaxHeight);

    &:not(:last-of-type) {
      margin-right: 32px;
    }

    &:not(:first-of-type) {
      position: relative;

      &::before {
        background-color: $color-white-primary;
        content: '';
        display: block;
        height: 24px;
        left: -16px;
        position: absolute;
        top: 50%;
        translate: -50% -50%;
        width: 1px;
      }
    }
  }

  img {
    display: block;
    filter: brightness(0) invert(1);
    height: 100%;
    max-height: var(--ddMaxHeight);
    max-width: 100%;
    width: auto;
  }
}

@media (max-width: 639px) {
  .EventSponsorStripDl {
    --ddMaxHeight: 20px;

    dt {
      width: 100%;
    }
  }
}
